import React, { useState, useEffect } from "react";
import '../index.css'
import { Flex, Box, Text, Image } from "@fluentui/react-northstar";
import logo_consultoria_telos from '../images/logo_consultoria_telos.png'

const DivPhone = (telephone, checkExtraPhone, extraPhone) => {
  if (!checkExtraPhone) {
    return (
      <Text
        id='telephone'
        content={telephone}
        fluid
        align="left"
        styles={{
          marginTop: '20px',
          color: '#595959',
          fontSize: '12px',
        }} />
    )
  } else {
    return (
      <div>
        <Text
        id='telephone'
        content={telephone}
        fluid
        align="left"
        styles={{
          marginTop: '8px',
          color: '#595959',
          fontSize: '12px',
        }} />
        <Text
          id='telephone'
          content={extraPhone}
          fluid
          align="left"
          styles={{
            color: '#595959',
            fontSize: '12px'
          }} />
      </div>
    )
  }
}

const TelosConsultoriaSignature = (name, email, role, telephone, extraPhone, checkExtraPhone) => {

  return (
    <Box id='signature'>
      <Flex
        gap="0"
        padding='0'
        styles={{
          minHeight: '150px',
          minWidth: '550px',
          maxHeight: '150px',
          maxWidth: '550px',
          backgroundColor: 'white'
        }}>
        <Flex.Item size="250px">
          <Box style={{
            borderWidth: '0px',
            borderRightWidth: '1.5px',
            borderStyle: 'solid',
            borderColor: '#a6a6a6',
            marginTop: '5px',
            marginBottom: '5px',
          }}>
            <Box
              fluid
              style={{
                position: 'relative',
                width: '250px',
                margin: 'auto',
                height: '100%',
                width: '100%',
                justifyContent: 'center',
                alignItems: 'center'
              }}>
              <Image src={logo_consultoria_telos}
                style={{
                  position: 'absolute',
                  margin: 'auto',
                  height: 'auto',
                  width: '90%',
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0
                }} />
            </Box>
          </Box>
        </Flex.Item>
        <Flex.Item size="280px">
          <Box
            style={{
              maxWidth: '280px',
              paddingTop: '5px',
              paddingBottom: '5px',
              marginTop: '8px',
              marginBottom: '8px',
              marginRight: '9px',
              marginLeft: '9px',
              fontFamily: 'Verdana, sans-serif',
              fontStyle: 'normal',
              fontWeight: 'bold',
              whiteSpace: 'nowrap'
            }} >
            <Text
              id='name'
              content={name}
              fluid
              align="left"
              styles={{
                color: '#595959',
                fontSize: '19px'
              }} />
            <Text
              id='role'
              content={role}
              fluid
              align="left"
              styles={{
                color: '#595959',
                fontSize: '12px',
                marginTop: '-5px'
              }} />
            <Text
              id='email'
              content={email}
              fluid
              align="left"
              styles={{
                marginTop: '38px',
                color: '#a6a6a6',
                fontSize: '11px',
                letterSpacing: '0.2px'
              }} />
            {DivPhone(telephone, checkExtraPhone, extraPhone)}
          </Box>
        </Flex.Item>
      </Flex>
    </Box >
  )
}
export default TelosConsultoriaSignature;