import React, { useEffect, useState } from "react"
import { Provider, teamsTheme, createT } from "@fluentui/react-northstar"
import * as microsoftTeams from "@microsoft/teams-js";
import { HashRouter as Router, Route } from "react-router-dom"
import { useTeams } from "@microsoft/teamsfx-react";


import Privacy from "./pages/Privacy";
import TermsOfUse from "./pages/TermsOfUse";
import Signature from "./pages/Signature";
import Home from "./pages/Home";
import TabConfig from "./pages/TabConfig";

export default function App() {
  const { theme } = useTeams({})[0];

  return (
    <Provider theme={theme || teamsTheme} styles={({ theme: { siteVariables } }) => ({
      backgroundColor: siteVariables.colorScheme.default.background2
    })}>
      <Router>
        <Route exact path="/" component={Home} />
        <Route exact path="/privacy" component={Privacy} />
        <Route exact path="/termsofuse" component={TermsOfUse} />
        <Route exact path="/tab" component={Signature} />
        <Route exact path="/configtab" component={TabConfig} />
      </Router>
    </Provider>
  );
}