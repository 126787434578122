import React, { useState, useEffect } from "react";
import '../index.css'
import { Flex, Divider, Box, Text, Image } from "@fluentui/react-northstar";
import logo_elo from '../images/logo_elo.png'

const DivPhone = (telephone, checkExtraPhone, extraPhone) => {
  if (!checkExtraPhone) {
    return (
      <Text
        id='telephone'
        content={telephone}
        fluid
        align="right"
        styles={{
          marginTop: '24px',
          color: '#4a4542',
          fontSize: '12px',
        }} />
    )
  } else {
    return (
      <div>
        <Text
          id='telephone'
          content={telephone}
          fluid
          align="right"
          styles={{
            marginTop: '13px',
            color: '#4a4542',
            fontSize: '12px',
          }} />
        <Text
          id='telephone'
          content={extraPhone}
          fluid
          align="right"
          styles={{
            color: '#4a4542',
            fontSize: '12px',
          }} />
      </div>
    )
  }
}


const EloSignature = (name, email, role, telephone, extraPhone, checkExtraPhone) => {

  return (
    <Box id='signature'>
      <Flex
        gap="0"
        padding='0'
        styles={{
          minHeight: '140px',
          minWidth: '420px',
          maxHeight: '140px',
          maxWidth: '420px',
          backgroundColor: 'white'
        }}>
        <Flex.Item size="220px">
          <Box
            style={{
              minWidth: '200px',
              marginTop: '8px',
              marginBottom: '8px',
              marginRight: '9px',
              marginLeft: '2px',
              fontFamily: 'Verdana, sans-serif',
              fontStyle: 'normal',
              fontWeight: 'bold',
              whiteSpace: 'nowrap'
            }} >
            <Text
              id='name'
              content={name}
              fluid
              align="right"
              styles={{
                color: '#4a4542',
                fontSize: '18px'
              }} />
            <Text
              id='role'
              content={role}
              fluid
              align="right"
              styles={{
                color: '#f9b337',
                fontSize: '12px',
                marginTop: '-5px'
              }} />
            <Text
              id='email'
              content={email}
              fluid
              align="right"
              styles={{
                marginTop: '24px',
                color: '#4a4542',
                fontSize: '11px',
                letterSpacing: '0.2px'
              }} />
            {DivPhone(telephone, checkExtraPhone, extraPhone)}
          </Box>
        </Flex.Item>
        <Flex.Item size="200px">
          <Box style={{
            maxWidth: '200px',
            borderWidth: '0px',
            borderLeftWidth: '1.5px',
            borderStyle: 'solid',
            borderColor: '#595959',
            marginTop: '5px',
            marginBottom: '5px',
          }}>
            <Box
              style={{
                position: 'relative',
                minWidth: '200px',
                margin: 'auto',
                height: '100%',
                width: '100%',
                justifyContent: 'center',
                alignItems: 'center'
              }}>
              <Image src={logo_elo}
                style={{
                  position: 'absolute',
                  margin: 'auto',
                  height: 'auto',
                  width: '95%',
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                }} />
            </Box>
          </Box>
        </Flex.Item>
      </Flex>
    </Box >
  )
}
export default EloSignature;